import React, { useState } from "react";
import { Parallax } from "react-scroll-parallax";
import { Link, animateScroll as scroll } from "react-scroll";
import "./styles.css";
import bg1 from "./img/bg1.webp";
import bg2 from "./img/bg2.webp";
import bg3 from "./img/bg3.webp";
import bg4 from "./img/bg4.jpg";
import bg5 from "./img/bg5.webp";

export default function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      {/* Burger Menu Icon */}
      <div className="burger-menu" onClick={toggleSidebar}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      {/* Sidebar with anchor links */}
      <aside className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <nav>
          <ul>
            <li><Link to="MADTec" smooth={true} duration={1000} onClick={toggleSidebar}>MADTec</Link></li>
            <li><Link to="data-analysis" smooth={true} duration={1000} onClick={toggleSidebar}>Data Analysis</Link></li>
            <li><Link to="technical-support" smooth={true} duration={1000} onClick={toggleSidebar}>Technical Support</Link></li>
            <li><Link to="gamification" smooth={true} duration={1000} onClick={toggleSidebar}>Developing Gamification</Link></li>
            <li><Link to="sweepstakes" smooth={true} duration={1000} onClick={toggleSidebar}>Sweepstakes Industry</Link></li>
            <li><Link to="contact" smooth={true} duration={1000} onClick={toggleSidebar}>Contact Us</Link></li>
          </ul>
        </nav>
      </aside>

      {/* Adjust sections to have corresponding ids */}
      <section id="MADTec">
        <div className="box">
          <Parallax x={[0, -20]}>
            <h2>MADTec</h2>
          </Parallax>
          <div className="container">
            <div className="imgBx jarallax">
              <img
                src={bg1}
                className="jarallax-img"
                alt="Data Analysis"
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "top",
                }}
              />
            </div>
            <Parallax y={[0, -200]}>
              <div className="content" data-jarallax-element="-200 0">
                <p>
                  At MADTec, we specialize in cutting-edge data analysis, CRM solutions, and seamless inter-platform management and support. Our innovative approach ensures your data works for you.
                </p>
              </div>
            </Parallax>
          </div>
        </div>
      </section>

      <section id="data-analysis">
        <div className="box">
          <Parallax x={[-20, 0]}>
            <h2>Data Analysis</h2>
          </Parallax>
          <div className="container">
            <div className="imgBx jarallax">
              <img src={bg2} className="jarallax-img" alt="Data Analysis" />
            </div>
            <Parallax y={[0, -200]}>
              <div className="content" data-jarallax-element="-200 0">
                <p>
                  Unlock the potential of your data with our advanced analytics solutions. We turn complex data into actionable insights.
                </p>
              </div>
            </Parallax>
          </div>
        </div>
      </section>

      <section id="technical-support">
        <div className="box">
          <Parallax x={[0, -20]}>
            <h2>Technical Support</h2>
          </Parallax>
          <div className="container">
            <div className="imgBx jarallax">
              <img src={bg3} className="jarallax-img" alt="Technical Support" />
            </div>
            <Parallax y={[0, -200]}>
              <div className="content" data-jarallax-element="-200 0">
                <p>
                  Providing 24/7 technical support to ensure your operations run smoothly. Our experts are always ready to assist you.
                </p>
              </div>
            </Parallax>
          </div>
        </div>
      </section>

      <section id="gamification">
        <div className="box">
          <Parallax x={[-20, 0]}>
            <h2>Developing Gamification</h2>
          </Parallax>
          <div className="container">
            <div className="imgBx jarallax">
              <img src={bg4} className="jarallax-img" alt="Gamification" />
            </div>
            <Parallax y={[0, -200]}>
              <div className="content" data-jarallax-element="-200 0">
                <p>
                  Innovating the gamification landscape to enhance user engagement. Our solutions make interactions more rewarding.
                </p>
              </div>
            </Parallax>
          </div>
        </div>
      </section>

      <section id="sweepstakes">
        <div className="box">
          <Parallax x={[0, -20]}>
            <h2>Sweepstakes Industry</h2>
          </Parallax>
          <div className="container">
            <div className="imgBx jarallax">
              <img src={bg5} className="jarallax-img" alt="Sweepstakes" />
            </div>
            <Parallax y={[0, -200]}>
              <div className="content" data-jarallax-element="-200 0">
                <p>
                  Pioneering in the sweepstakes industry with robust and fair solutions. We provide seamless integration and management support.
                </p>
              </div>
            </Parallax>
          </div>
        </div>
      </section>

      <section id="contact">
        <div className="box">
          <Parallax x={[-20, 0]}>
            <h2>Contact Us</h2>
          </Parallax>
          <div className="container">
            <Parallax y={[0, -150]}>
              <div className="content" data-jarallax-element="-200 0">
                <p>Empresa registada: MENDES ALVES DIAS, UNIPESSOAL LDA </p>
                <p>Address: Rua Francisco Luis Silva, nº 8, r/ch, 6150-526, Castelo Branco </p>
                <p>Email: admin@madtec.co</p>
              </div>
            </Parallax>
          </div>
        </div>
      </section>
    </>
  );
}
